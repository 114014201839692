<template>
  <!-- 品牌专区 -->
  <Layout>
    <div class="main-shop">
      <div class="base-width">
        <!-- 面包屑 -->
        <BreadCrumb :list='routes' :icon='false' />
        <!-- 广告图 -->
        <Banner class="img" :list="bannerList" height="4.92rem" v-ani.fade-up />
      </div>
      <div class="c-layout-3">
        <div class="base-width">
          <div class="c-layout-3__lf">
            <!-- 精选商品 -->
            <ProductSelected :list="selectProduArr" />
          </div>
          <div class="c-layout-3__main">
            <!-- 筛选 -->
            <ProductFilter :totalNum="goodsTotal" @change="filterFn" v-ani.fade-up />
            <!-- 产品列表 -->
            <div class="md-prod-list">
              <template v-for="item in goodsArr">
                <ProductItem :value='item' :key="item.goods_id" v-ani.fade-up />
              </template>
            </div>
            <!-- 分页 -->
            <Pagination v-show="goodsTotal > 0" :page="goodsData.p" :size="goodsData.page_num" :total="goodsTotal"
              v-ani.fade-up @change="changePage" :key="pagKey" />
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { IndexBannerApi } from "@/request/api/user";
import { SelectedProductsApi, GoodsListApi } from "@/request/api/shop";
export default {
  created() {
    this.goodsData.shop_type = this.$route.query.shop_type;
    if (this.goodsData.shop_type == 'brand') {
      this.routes[1].name = '品牌专区';
    } else {
      this.routes[1].name = '精选专区';
    }
    // 轮播
    IndexBannerApi({ from: 428 }).then(({ status, data }) => {
      if (status == 200) {
        this.bannerList = data;
      }
    })
    // 精选商品
    SelectedProductsApi().then(({ status, data }) => {
      if (status == 200) {
        this.selectProduArr = data.list;
      }
    })
    this.goodsFn();
  },
  data() {
    return {
      bannerList: [], // 轮播图列表
      goodsData: {
        p: 1,
        page_num: 12,
        order: 1,
        sort: "",
        min_price: "",
        max_price: "",
        shop_type: 'brand'
      },
      goodsArr: [],
      goodsTotal: 0,
      pagKey: 0,
      selectProduArr: [],
      routes: [
        { name: '首页', link: '/' },
        { name: '品牌专区', link: '/brand' }
      ]
    };
  },
  methods: {
    // 商品列表
    goodsFn() {
      GoodsListApi(this.goodsData).then(({ status, data }) => {
        if (status == 200) {
          this.goodsArr = data.list;
          this.goodsTotal = Number(data.total);
          this.pagKey++;
        }
      })
    },
    // 参数筛选
    filterFn(obj) {
      this.goodsData.p = 1;
      this.goodsData.order = obj.order;
      this.goodsData.sort = obj.sort;
      this.goodsData.min_price = obj.min_price;
      this.goodsData.max_price = obj.max_price;
      this.goodsFn();
    },
    // 分页
    changePage(page) {
      this.goodsData.p = page;
      this.goodsFn();
      window.scroll({ top: 500, behavior: "smooth", });
    },
  }
};
</script>

<style lang="scss" scoped>
.main-shop {
  padding-bottom: .6rem;

  .md-pagination {
    margin-top: .4rem;
  }

  .md-prod-list {
    margin-top: .2rem;
    display: flex;
    flex-wrap: wrap;

    .c-product-item {
      width: 3rem;
      margin-bottom: .2rem;
      margin-right: .2rem;

      &:nth-of-type(4n) {
        margin-right: 0;
      }
    }
  }

  .c-breadcrumbs {
    margin: 0.27rem 0 .26rem;
  }

  ::v-deep .banner .text {
    left: 0;
    right: 1.25rem;
    text-align: right;
  }

  .md-filter {
    margin-top: .8rem;
  }

  .md-product-selected {
    margin-top: .35rem;

    ::v-deep .list {
      margin-top: .17rem;
    }
  }
}
</style>